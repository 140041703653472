/* body */

body {
  background: rgb(156,203,236);
  background: linear-gradient(-10deg, rgba(156,203,236,1) 50%, rgba(176,215,243,1) 50%);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* animations */
  /* rotation */

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation_anticlockwise {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

  /* floating */

  @keyframes float {
    0% {
      transform: translatey(5px);
    }
    50% {
      transform: translatey(-5px);
    }
    100% {
      transform: translatey(5px);
    }
  }